<template>
  <v-container fluid class="ma-0 pa-0">
    <back-to-store
      :title="$t('shippingBilling.shippingInformation')"
      v-if="currentStep === 1"
    ></back-to-store>
    <back-to-store
      :title="$t('shippingBilling.foundUs')"
      v-if="currentStep === 2 && addressCart.shipping.state !== 'Santo Domingo'"
    ></back-to-store>
    <back-to-store
      :title="$t('shippingBilling.billingInformation')"
      v-if="currentStep === 2 && addressCart.shipping.state === 'Santo Domingo'"
    ></back-to-store>
    <back-to-store :title="$t('shippingBilling.resumen')" v-if="currentStep === 3"></back-to-store>
    <back-to-store :title="$t('shippingBilling.resumen')" v-if="currentStep === 4"></back-to-store>
    <v-container class="pa-lg-16 ">
      <template>
        <v-stepper v-model="currentStep" class="elevation-0">
          <v-stepper-header class="elevation-0 step-payment px-10 py-3 pa-lg-0 d-none">
            <v-stepper-step step="1"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2"> </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="3"> </v-stepper-step>
            <v-stepper-step step="4"> </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-form ref="shippingInformation">
                <v-row>
                  <v-col cols="12" lg="6">
                    <p class="p josefin-light ">{{ $t("profile.firstName") }}</p>
                    <v-text-field
                      outlined
                      v-model="addressCart.shipping.first_name"
                      :rules="validationRules.nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <p class="p josefin-light ">{{ $t("profile.lastName") }}</p>
                    <v-text-field
                      outlined
                      v-model="addressCart.shipping.last_name"
                      :rules="validationRules.nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="8">
                    <p class="p josefin-light">{{ $t("profile.email") }}</p>
                    <v-text-field
                      outlined
                      v-model="addressCart.shipping.email"
                      :rules="validationRules.emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <p class="p josefin-light">{{ $t("shippingBilling.phone") }}</p>
                    <v-text-field
                      outlined
                      v-model="addressCart.shipping.phone"
                      :rules="validationRules.phoneRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="mx-0">
                  <v-col cols="12">
                    <v-row>
                      <p class="p josefin-light">{{ $t("shippingBilling.addressShipping") }}</p>
                    </v-row>
                    <v-row>
                      <v-text-field
                        outlined
                        v-model="addressCart.shipping.address1[0]"
                        :rules="validationRules.addressRules"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="px-3 country_state">
                  <v-col cols="12" lg="4" class="pr-lg-10">
                    <v-row>
                      <p class="p josefin-light">{{ $t("shippingBilling.province") }}</p>
                    </v-row>
                    <v-row>
                      <v-select
                        :items="getProvinces"
                        label="Elegir"
                        v-model="addressCart.shipping.state"
                        dense
                        item-text="name"
                        outlined
                        :rules="validationRules.stateRules"
                        @change="setRegion(addressCart.shipping.state)"
                      ></v-select>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="4" class="px-lg-10">
                    <v-row>
                      <p class="p josefin-light">{{ $t("shippingBilling.municipality") }}</p>
                    </v-row>
                    <v-row>
                      <v-text-field
                        outlined
                        v-model="addressCart.shipping.city"
                        :rules="validationRules.cityRules"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-col cols="12" lg="4" class="pl-lg-10">
                    <v-row>
                      <p class="p josefin-light">{{ $t("shippingBilling.postCode") }}</p>
                    </v-row>
                    <v-row>
                      <v-text-field
                        outlined
                        v-model="addressCart.shipping.postcode"
                        :rules="validationRules.postCodeRules"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                </v-row>
              </v-form>

              <v-row class="py-5" justify="center">
                <v-btn
                  class="btn_second"
                  v-if="!isOnUpdate"
                  @click="nextStep(2, 'shippingInformation')"
                  width="268"
                >
                  {{ $t("shippingBilling.btnNext") }}
                </v-btn>
                <v-btn
                  class="btn_second"
                  v-if="isOnUpdate"
                  @click="nextStep(3, 'shippingInformation')"
                  width="268"
                >
                  {{ $t("shippingBilling.btnUpdate") }}
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-row
                v-if="addressCart.shipping.state === 'Santo Domingo'"
                class="mx-0 py-lg-10"
              >
              <v-col cols="12" >
                <v-form ref="billingInformation">
                  <v-row class="px-3 mt-n4">
                    <v-col cols="12" lg="6" class="pr-lg-5">
                      <v-row>
                        <p class="p josefin-light">{{ $t("profile.firstName") }}</p>
                      </v-row>
                      <v-row>
                        <v-text-field
                          outlined
                          v-model="addressCart.billing.first_name"
                          :rules="validationRules.namesRules"
                        ></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="6" class="px-lg-5">
                      <v-row>
                        <p class="p josefin-light">{{ $t("profile.lastName") }}</p>
                      </v-row>
                      <v-row>
                        <v-text-field
                          outlined
                          v-model="addressCart.billing.last_name"
                          :rules="validationRules.nameRules"
                        ></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-col cols="12" class="pt-5">
                    <v-row>
                      <p class="p josefin-light">{{ $t("shippingBilling.address") }}</p>
                    </v-row>
                    <v-row>
                      <v-text-field
                        outlined
                        v-model="addressCart.billing.address1[0]"
                        :rules="validationRules.addressRules"
                      ></v-text-field>
                    </v-row>
                  </v-col>
                  <v-row class="px-3 country_state pt-5">
                    <v-col cols="12" lg="4" class="pr-lg-10">
                      <v-row>
                        <p class="p josefin-light">{{ $t("shippingBilling.province") }}</p>
                      </v-row>
                      <v-row>
                        <v-select
                          :items="getProvinces"
                          label="Elegir"
                          v-model="addressCart.billing.state"
                          dense
                          item-text="name"
                          outlined
                          :rules="validationRules.stateRules"
                        ></v-select>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="4" class="px-lg-10">
                      <v-row>
                        <p class="p josefin-light">{{ $t("shippingBilling.municipality") }}</p>
                      </v-row>
                      <v-row>
                        <v-text-field
                          outlined
                          v-model="addressCart.billing.city"
                          :rules="validationRules.cityRules"
                        ></v-text-field>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="4" class="pl-lg-10">
                      <v-row>
                        <p class="p josefin-light">{{ $t("shippingBilling.postCode") }}</p>
                      </v-row>
                      <v-row>
                        <v-text-field
                          outlined
                          v-model="addressCart.billing.postcode"
                          :rules="validationRules.postCodeRules"
                        ></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="py-5" justify="center">
                  <v-btn
                    class="btn_second mt-lg-7"
                    v-if="!isOnUpdate"
                    @click="saveAddressToCartAndDefaultAddress()"
                    width="368"
                  >
                    {{ $t("shippingBilling.btnUseAddress") }}
                  </v-btn>
                  <v-btn
                    class="btn_second"
                    v-if="isOnUpdate"
                    @click="nextStep(3, 'billingInformation')"
                    width="268"
                  >
                    {{ $t("shippingBilling.btnUpdate") }}
                  </v-btn>
                </v-row>
                </v-form>
              </v-col>
              <v-col cols="12">
                 <v-row class="mx-0" v-if="getShippingDetails.rates">
                  <p class="copperplate h2 font-weight-bold">
                    {{ $t("shippingBilling.shippingMethods") }}
                  </p>
                </v-row>
                <v-row class="mx-0" v-if="getShippingDetails.rates">
                  <v-radio-group v-model="shippingMethod" row>
                    <v-radio
                      v-for="(item, index) in getShippingDetails.rates"
                      :key="index"
                      :label="item.carrier_title + ' (RD$' + item.rates[0].price + ')'"
                      :value="item.rates[0].method"
                      @click="saveShippingMethodSeleted(shippingMethod)"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row class="mx-0 mt-10" v-if="getPaymentDetails.methods">
                  <p class="copperplate h2 font-weight-bold">
                    {{ $t("shippingBilling.billingMethods") }}
                  </p>
                </v-row>
                <v-row class="mx-0" v-if="getPaymentDetails.methods">
                  <v-radio-group v-model="paymentMethod" row>
                    <v-radio
                      v-for="(item, index) in getPaymentDetails.methods"
                      :key="index"
                      :label="item.description"
                      :value="item.method"
                      @click="savePaymentToCart(paymentMethod)"
                    ></v-radio>
                  </v-radio-group>
                </v-row>
                <v-row class="py-5" justify="center">
                  <v-btn
                    class="btn_second"
                    v-if="!isOnUpdate && paymentMethod"
                    @click="nextStep(3, 'billingInformation')"
                    width="268"
                  >
                    {{ $t("shippingBilling.btnNext") }}
                  </v-btn>
                </v-row>
              </v-col>
              </v-row>
              <v-row v-else>
                <v-col cols="8">
                  <p class="p black-grin josefin-light">
                    {{ $t("shippingBilling.foundUsMessages") }}
                  </p>
                </v-col>
                <v-col cols="12" lg="12" class="ma-0 pa-0">
                  <v-col class="d-flex" cols="4">
                    <v-select
                      solo
                      v-model="city"
                      :items="getCities"
                      item-text="name"
                      item-value="name"
                      class="select_location"
                      single-line
                    ></v-select>
                  </v-col>
                  <v-col class=" input_location" cols="12">
                    <v-list class="transparent list-location">
                      <v-list-item-group>
                        <v-row>
                          <v-col v-for="(item, index) in getLocations[city]" :key="index" cols="4">
                            <v-list-item @click="getIdLocation(item)" class="pa-0">
                              <v-list-item-content>
                                <v-row class="ma-0 justify-center justify-sm-start pr-lg-10">
                                  <v-list-item-title
                                    v-text="item.text"
                                    class="copperplate-bold h2 text-center text-lg-left"
                                  ></v-list-item-title>
                                  <p v-text="item.address" class="p josefin-light text-justify"></p>
                                </v-row>
                              </v-list-item-content>
                            </v-list-item>
                          </v-col>
                        </v-row>
                      </v-list-item-group>
                    </v-list>
                  </v-col>
                </v-col>
                <v-col cols="12" lg="12" class="pt-6 pr-lg-16">
                  <iframe
                    width="100%"
                    height="350"
                    style="border:0"
                    loading="lazy"
                    allowfullscreen
                    :src="
                      `https://www.google.com/maps/embed/v1/place?key=AIzaSyChODmXijkuuQLsfPCbHgQ8ueXYLqJezRI
            &q=${getLocation.address}`
                    "
                  >
                  </iframe>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-col cols="12" class="py-16">
                <h3
                  class="text-h5 black-grin pb-5"
                  v-html="$t('cart.subtitle', { count: getCart.items_count })"
                ></h3>
                <hr class="hr-sub-menu pb-5" />
                <v-row class="d-none d-lg-flex">
                  <v-simple-table style="min-width: 100% !important">
                    <template v-slot:default>
                      <thead class="josefin-light font-weight-light ">
                        <tr>
                          <th class="text-center p">
                            {{ $t("cart.quantity") }}
                          </th>
                          <th class="text-left p">
                            {{ $t("cart.item") }}
                          </th>
                          <th class="text-center p">
                            {{ $t("cart.price") }}
                          </th>
                          <th class="text-center p">
                            {{ $t("cart.total") }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in getCart.items" :key="index">
                          <td class="font-weight-regular text-center josefin-light">
                            {{ item.quantity }}
                          </td>
                          <td class=" josefin-light ">
                            <span class="copperplate-medium"> {{ item.name }}</span>
                          </td>
                          <td class="font-weight-regular text-center josefin-light">
                            RD${{ Intl.NumberFormat().format(item.price) +'.00' }}
                          </td>
                          <td class="font-weight-bold text-center josefin-light">
                            RD${{ Intl.NumberFormat().format(item.base_total) + '.00' }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-row>
                <v-row
                  class="d-lg-none py-3"
                  v-for="(item, productIndex) in getCart.items"
                  :key="productIndex"
                >
                  <v-col cols="12">
                    <span
                      class="copperplate text-center"
                      v-html="item.product.short_description"
                    ></span>
                    <v-row justify="center">
                      <span class="josefin-light text-center">{{ item.name }}</span>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="mt-3">
                    <v-row>
                      <v-col
                        ><p class="josefin-light font-weight-bold ">
                          {{ $t("cart.price") }}
                        </p></v-col
                      >
                      <v-col class="justify-end d-flex">
                        <p class="josefin-light font-weight-bold">${{ item.price }}</p></v-col
                      >
                    </v-row>
                    <v-row>
                      <v-col
                        ><p class="josefin-light font-weight-bold ">
                          {{ $t("cart.quantity") }}
                        </p></v-col
                      >
                      <v-col class="justify-end d-flex">
                        <p class="josefin-light font-weight-bold">{{ item.quantity }}</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        ><p class="josefin-light font-weight-bold ">
                          {{ $t("cart.total") }}
                        </p></v-col
                      >
                      <v-col class="justify-end d-flex">
                        <p class="josefin-light font-weight-bold">${{ item.total }}</p></v-col
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="hr-step3">
                  <v-col>
                    <v-row justify="end" class="pr-lg-16 ma-0">
                      <p class="font-weight-bold copperplate ma-0">
                        SUB-TOTAL:
                        RD${{ Intl.NumberFormat().format( getCart.base_sub_total) + '.00' }}
                      </p>
                    </v-row>
                    <v-row justify="end" class="pr-lg-16 ma-0">
                      <p class="font-weight-regular copperplate ma-0">
                         {{$t("cart.taxesIncluded")}}
                      </p>
                    </v-row>
                    <v-row justify="end" class="pr-lg-16 ma-0">
                      <p class="font-weight-regular copperplate ma-0" v-if="getCart.selected_shipping_rate">
                        ENVIO: RD${{ Intl.NumberFormat().format(getCart.selected_shipping_rate.base_price) + '.00' }}
                      </p>
                    </v-row>
                    <v-row justify="end" class="pr-lg-16 ma-0">
                      <p class="font-weight-regular copperplate ma-0">
                        TOTAL: RD${{ Intl.NumberFormat().format(getCart.base_grand_total) + '.00' }}
                      </p>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-row class="px-3 pb-16" justify="center">
                <v-col cols="12" lg="6">
                  <v-row>
                    <h3 class="text-h5 black-grin">
                      {{ $t("shippingBilling.shippingInformationPreview") }}
                    </h3>
                  </v-row>
                  <v-row>
                    <p class="josefin-light p black-grin">
                      {{ addressCart.shipping.first_name + " " + addressCart.shipping.last_name }}
                      <br />
                      {{ addressCart.shipping.email }} <br />
                      {{ addressCart.shipping.phone }} <br />
                      {{ addressCart.shipping.address1[0] + ", " + addressCart.shipping.city }}
                      <br />
                      {{ addressCart.shipping.state + " " + addressCart.shipping.postcode }}
                    </p>
                  </v-row>
                  <v-row class="py-5">
                    <v-btn class="btn_first" @click="updateStep(1)" width="217">
                      {{ $t("shippingBilling.btnChange") }}
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <h3 class="text-h5 black-grin">
                      {{ $t("shippingBilling.billingInformation") }}
                    </h3>
                  </v-row>
                  <v-row>
                    <p class="josefin-light p black-grin">
                      {{ addressCart.billing.first_name + " " + addressCart.billing.last_name }}
                      <br />
                      {{ addressCart.billing.email }} <br />
                      {{ addressCart.billing.phone }} <br />
                      {{ addressCart.billing.address1[0] + ", " + addressCart.billing.city }} <br />
                      {{ addressCart.billing.state + " " + addressCart.billing.postcode }}
                    </p>
                  </v-row>
                  <v-row class="py-5">
                    <v-btn class="btn_first" @click="updateStep(2)" width="217">
                      {{ $t("shippingBilling.btnChange") }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="py-5" justify="center">
                <v-btn class="btn_second" @click="saveOrder()" width="268" :disabled="!btnActive">
                  {{ $t("shippingBilling.btnPay") }}
                </v-btn>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </template>
    </v-container>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import backToStore from "@/components/home/back-to-store.vue";
import router from '../../router';

export default {
  name: "paymentInfo",
  components: {
    backToStore
  },
  async created() {
    // await this.initInfo();
    // await this.fetchCartDetails();
     if (!this.getCart) {
     
       this.$router.push('/productos');
      
    } 
    if (this.saveAddressToCart) {
      console.log(this.saveAddressToCart);
    }
 
  },
  data() {
    return {
      city: "Santo Domingo",
      local: 1,
      provinces: [],
      currentStep: 1,
      isOnUpdate: false,
      addressCart: {
        billing: {
          address1: [null],
          use_for_shipping: false,
          first_name: null,
          last_name: null,
          email: "dari@mixart.do",
          city: null,
          state: null,
          country: "DO",
          phone: "8299598933",
          postcode: null
        },
        shipping: {
          address1: [""],
          first_name: null,
          last_name: null,
          email: null,
          city: "",
          state: "",
          country: "DO",
          phone: "",
          postcode: ""
        }
      },
      shippingMethod: null,
      paymentMethod: null,
      btnActive: true,
    };
  },
  computed: {
    ...mapGetters("shoppingCart", [
      "getCart",
      "getShippingDetails",
      "getPaymentDetails",
      "getOrderDetails",
    ]),
    ...mapGetters("customer", ["getUser"]),
    ...mapGetters("addresses", ["getAddressDefault"]),
    ...mapGetters("locations", [
      "getProvinces",
      "getLocations",
      "getCity",
      "getCities",
      "getLocation"
    ]),
    validationRules() {
      return {
        nameRules: [
          (v) => !!v || "Campo requerido",
          (v) => (v && v.length <= 60) || "60 carácteres máximo"
        ],
        emailRules: [
          (v) => !!v || "Campo requerido",
          (v) => /.+@.+\..+/.test(v) || "El correo no es válido"
        ],
        addressRules: [(v) => !!v || "Campo requerido"],
        stateRules: [(v) => !!v || "Campo requerido"],
        cityRules: [(v) => !!v || "Campo requerido"],
        postCodeRules: [(v) => !!v || "Campo requerido", (v) => /^\d*$/.test(v) || "Solo números"],
        phoneRules: [
          (v) => !!v || "Campo requerido",
          (v) => /^\d*$/.test(v) || "Solo números",
          (v) => (v && v.length >= 10) || "Mínimo 10 números",
          (v) => (v && v.length <= 14) || "Máximo 14 números"
        ]
      };
    },
  },
  methods: {
    ...mapActions("shoppingCart", [
      "saveAddressToCart",
      "saveShippingToCart",
      "savePaymentToCart",
      "fetchCartDetails",
      "saveOrderDetails",
      "payOrder",
    ]),
    ...mapActions("locations", ["initInfo", "getIdLocation"]),
    ...mapActions("addresses", ["fetchCreateAddress"]),
    nextStep(step, form) {
      if (this.$refs[form].validate()) {
        this.currentStep = step;
      }
      if (this.isOnUpdate) {
        this.isOnUpdate = false;
      }
    },
    updateStep(step) {
      if (this.addressCart.shipping.state === "Santo Domingo") {
        this.currentStep = step;
        this.isOnUpdate = true;
      } else {
        this.currentStep = 2;
      }
    },
    setRegion(region) {
      const test = this.getProvinces.find((provincia) => provincia.name === region);
      this.city = test.region;
    },
    autoCompleteUserInAddress() {
      this.addressCart.shipping.first_name = this.getUser.first_name;
      this.addressCart.shipping.last_name = this.getUser.last_name;
      this.addressCart.shipping.email = this.getUser.email;
      this.addressCart.billing.first_name = this.getUser.first_name;
      this.addressCart.billing.last_name = this.getUser.last_name;
      this.addressCart.billing.email = this.getUser.email;
    },

    setAddressToCart() {
      if (this.getAddressDefault) {
        this.addressCart.shipping = { ...this.getAddressDefault };
        this.addressCart.billing = { ...this.getAddressDefault };
      }
      this.autoCompleteUserInAddress();
    },
    saveAddressToCartAndDefaultAddress() {
      if (!this.getAddressDefault) {
        this.addressCart.shipping = { ...this.addressCart.shipping, default_address: true };
        this.fetchCreateAddress(this.addressCart.shipping);
      }
      this.saveAddressToCart(this.addressCart);
    },
    saveShippingMethodSeleted(item) {
      this.saveShippingToCart(item).then(()=>{
         this.fetchCartDetails();
      });
    },
    async saveOrder() {
      this.btnActive = false;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          container: "contact-message-modal",
          confirmButton:
            "btn-success v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default",
          title: "h6"
        },
        buttonsStyling: false
      });

      try {
        await this.saveOrderDetails();
        const orderData = await this.payOrder(this.getOrderDetails.id);

        window.location.href = orderData.redirect_url;
      } catch (err) {
        console.error(err);
        swalWithBootstrapButtons.fire({
          title: this.$t('alerts.errorMessage'),
          text: this.$t('alerts.tryLater'),
          confirmButtonText: this.$t('alerts.btnConfirm'),
          icon: "error"
        });
      }
    }
  },
  watch: {
    getAddressDefault: {
      immediate: true,
      handler() {
        this.setAddressToCart();
      }
    },

  }
};
</script>
<style lang="scss" scoped>
.theme--light.v-input {
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0;
}
thead {
  th {
    border: none !important;
  }
}
tbody {
  td {
    border: none !important;
    padding-bottom: 30px !important;
    padding-top: 20px !important;
    font-size: 16px !important;
  }
}
.hr-step3 {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}
@media screen and (min-width: 600px) {
  .v-stepper__step:first-child {
    margin-right: -150px;
    margin-left: 150px;
  }
  .v-stepper__step:last-child {
    margin-left: -150px !important;
    margin-right: 150px !important;
  }
}
.v-divider {
  border-width: 3px 0 0 0;
}
.v-list-item__title,
.v-list-item__subtitle {
  white-space: normal !important;
}
.v-stepper {
  min-height: 85vh !important;
}
</style>
